.mapBoxSwitchBtnDiv {
    position: absolute;
    margin-top: 1.25vw;
    margin-left: 1.25vw;
}

.mapboxgl-ctrl-bottom-right {
    display: none;
}

.mapBoxSwitchBtnDiv>button {
    box-shadow: 0vw 0.0625vw 0.25vw -0.0625vw #0000004D;
    background: #FFFFFF;
    border: none;
    padding: 0.53125vw 1.0625vw;
    font-size: 1.125vw;
    font-weight: 400;
    font-family: 'inter';
}

.mapBoxSwitchBtnDiv>button:hover {
    background: #FFFFFF;
    opacity: 0.9;
}

.mapBoxSwitchBtn1 {
    border-top-left-radius: 0.125vw;
    border-bottom-left-radius: 0.125vw;
}

.mapBoxSwitchBtn2 {
    border-top-right-radius: 0.125vw;
    border-bottom-right-radius: 0.125vw;
}

.mapBoxZoomBtnContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: fit-content;
    box-shadow: 0vw 0.0625vw 0.25vw -0.0625vw #0000004D;
    background-color: #FFFFFF;
    border-radius: 0.125vw;
}

.mapBoxZoomBtnContainer>button {
    background: #FFFFFF;
    border: none;
    padding: 0.6875vw;
    font-weight: 400;
    font-family: 'inter';
}

.mapBoxZoomBtnContainer>button:hover {
    background: #FFFFFF;
    opacity: 0.9;
}

.mapBoxZoomBtnContainerMainDiv {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: 1.25vw;
    margin-top: -7.50vw;
    display: flex;
    float: right;
    z-index: 9;
    position: relative;
    width: fit-content;
}

.mapZoomInIcon {
    width: 1.125vw;
    height: 1.125vw;
}

.mapZoomOutIcon {
    width: 1.125vw;
    height: 1.125vw;
}

@media only screen and (max-width: 768px) {
    .mapBoxSwitchBtnDiv {
        position: absolute;
        margin-top: 2vw;
        margin-left: 2vw;
    }

    .mapBoxSwitchBtnDiv>button {
        box-shadow: 0vw 0.0625vw 0.25vw -0.0625vw #0000004D;
        background: #FFFFFF;
        border: none;
        padding: 1vw 2vw;
        font-size: 2.7vw;
        font-weight: 400;
        font-family: 'inter';
    }

    .mapBoxSwitchBtn1 {
        border-top-left-radius: 0.5vw;
        border-bottom-left-radius: 0.5vw;
    }

    .mapBoxSwitchBtn2 {
        border-top-right-radius: 0.5vw;
        border-bottom-right-radius: 0.5vw;
    }

    .mapBoxZoomBtnContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: fit-content;
        box-shadow: 0vw 0.0625vw 0.25vw -0.0625vw #0000004D;
        background-color: #FFFFFF;
        border-radius: 0.5vw;
    }

    .mapBoxZoomBtnContainer>button {
        background: #FFFFFF;
        border: none;
        padding: 0.2vw 1.5vw;
        font-weight: 400;
        font-family: 'inter';
    }

    .mapBoxZoomBtnContainer>button:hover {
        background: #FFFFFF;
        opacity: 0.9;
    }

    .mapBoxZoomBtnContainerMainDiv {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-right: 2.5vw;
        margin-top: -15.50vw;
        display: flex;
        float: right;
        z-index: 9;
        position: relative;
        width: fit-content;
    }

    .mapZoomInIcon {
        width: 2.5vw;
        height: 2.5vw;
    }

    .mapZoomOutIcon {
        width: 2.5vw;
        height: 2.5vw;
    }
}