@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap");

body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
}
@font-face {
  font-family: "inter";
  src: local("inter"), url('./data/Inter/static/Inter-Regular.ttf') format("truetype");
}
@font-face {
  font-family: "interSemiBold";
  src: local("InterSemiBold"),
    url('./data/Inter/static/Inter-SemiBold.ttf') format("truetype");
}
@font-face {
  font-family: "interBold";
  src: local("InterBold"),
    url('./data/Inter/static/Inter-Bold.ttf') format("truetype");
}
@font-face {
  font-family: "poppins";
  src: local("poppins"), url(./data/Poppins/Poppins-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "poppinsSemiBold";
  src: local("poppinsSemiBold"),
    url(./data/Poppins/Poppins-SemiBold.ttf) format("truetype");
}
@font-face {
  font-family: "poppinsBold";
  src: local("poppinsBold"), url(./data/Poppins/Poppins-Bold.ttf) format("truetype");
}
@tailwind base;
@tailwind components;
@tailwind utilities;
