.createBtn {
    background-color: #f9f9f9;
    width: 150px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: 1px solid lightgray;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    border-radius: 5px;
    font-size: 18px;
    color: #000;
    gap: 10px;
}

.createBtn:hover {
    background-color: rgb(0 0 0 / 0.08);
    width: 150px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: 1px solid lightgray;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    border-radius: 5px;
    font-size: 18px;
    color: #000;
    gap: 10px;
}

.countryFormMainDiv {
    width: 100%;
    border: 1px solid #e0e0e0;
}

.InputBoxCss {
    outline: none;
    border: 1px solid black;
    padding: 5px;
    width: auto;
    /* height: 30px; */
    border-radius: 5px;
    font-size: 16px;
}

.AddBtn {
    width: 260px;
    margin: 50px auto 20px;
    height: 47px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background-color: #03c9d7;
    color: White;
    font-size: 20px;
    font-weight: 700;
    cursor: pointer;
}
.downloadBtn {
    width: 350px;
    margin: 50px auto 20px;
    height: 47px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background-color: #03c9d7;
    color: White;
    font-size: 20px;
    font-weight: 700;
    cursor: pointer;
}

.outerMapContainer {
    height: 350px;
    width: 450px;
    border: 1px solid #000;
    margin-top: 20px;
}

.selectLatLongDiv {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
    gap: 30px;
    height: 500px;
}

.selectLatLongDiv>div {
    margin-top: 30px;

}

.confirmOkBtn {
    width: 120px;
    margin-top: 5px;
    height: 47px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background-color: #03c9d7;
    color: White;
    font-size: 18px;
    font-weight: 700;
    cursor: pointer;
}

.pagingNumber {
    height: 25px;
    width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: #000;
    padding-top: 1.5px;
}

.pagingNumberSelected {
    height: 25px;
    width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background: #e3165b;
    color: #fff;
    opacity: 1;
    border-radius: 50%;
    padding-top: 1.5px;
}

/* .widgetFrame{
    border: 10px solid red;
} */
.changeImg {
    width: 150px;
    margin: 10px 0px 0px 0px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background-color: #03c9d7;
    color: White;
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;
}

.dropDownDiv {
    width: 350px;
    max-height: 180px;
    border: 1px solid grey;
    border-radius: 4px;
    padding: 2px;
    position: absolute;
    background-color: #fff;
    overflow-y: scroll;
    z-index: 1;
}
.dropDownDivCurrencies {
    width: 200px;
    max-height: 180px;
    border: 1px solid grey;
    border-radius: 4px;
    padding: 2px;
    position: absolute;
    background-color: #fff;
    overflow-y: scroll;
    z-index: 1;
}

.countryDropDownItemDiv {
    padding: 5px 10px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.countryDropDownItemActiveDiv {
    padding: 5px 10px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(236, 240, 241);
}

.countryDropDownItemDiv:hover {
    background-color: rgba(236, 240, 241);
}

.dayHoursGrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
    gap: 15px 30px;
}

.dayHoursInputBoxCss {
    outline: none;
    border: 1px solid #ccc;
    padding: 5px;
    width: auto;
    border-radius: 3px;
    font-size: 14px;
}

.dayHoursInputBoxCss:hover {
    border: 1px solid #000;
}

.addTicketType:hover {
    text-decoration: underline;
}


.selectPOITypeMainDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px
}

.selectPOITypeMainDiv>div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 15px
}

.selectPOITypeMainIconDiv {
    border: 2px solid blue;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    padding: 2px;
    cursor: pointer;
}

.selectPOITypeMainIconSelectedDiv {
    background: blue;
    width: 100%;
    height: 100%;
    border-radius: 50%
}

.gpxLevelNameBtn {
    padding: 5px 10px;
    border: 1px solid black;
    color: #000;
    background-color: #fff;
    width: 125px;
    cursor: pointer;
    text-align: center;
    font-size: 16px;
}

.gpxLevelNameBtn:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

.gpxLevelNameBtnActive {
    padding: 5px 10px;
    border: 1px solid black;
    color: #fff;
    background-color: blue;
    width: 125px;
    cursor: pointer;
    text-align: center;
    font-size: 16px;
}

.gpxTrailOptions {
    margin-top: 30px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    margin-bottom: 30px;
}

.gpxTrailOptions>div {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.datePickerCss>.e-input-group::before {
    border: unset !important;
    margin-bottom: 0 !important;
    background-color: unset !important;
}

.datePickerCss>.e-input-group {
    border: unset !important;
    margin-bottom: 0 !important;
}

.datePickerCss>.e-input-focus {
    border: none !important;
    margin-bottom: 0 !important;
    background-color: unset !important;
}

.datePickerCss>.e-input-focus::before {
    border: none !important;
    margin-bottom: 0 !important;
    background-color: unset !important;
}

.datePickerCss>.e-input-group::after {
    border: none !important;
    margin-bottom: 0 !important;
    background-color: unset !important;
}

.datePickerCss>.e-input-focus::after {
    border: none !important;
    margin-bottom: 0 !important;
    background-color: unset !important;
}

.addLocalPoiWayPointText {
    color: #3f51b5;
    font-size: 18px;
    cursor: pointer;
    margin-top: 15px;
    margin-bottom: 15px;
}

.addLocalPoiWayPointText:hover {
    text-decoration: underline;
}

.wayPointPoiChip {
    background-color: #7e7e7e14;
    width: 300px;
    padding: 5px 15px;
    border-radius: 50px;
    height: 35px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    font-size: 14px;
}


.selfGuidedTable {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
}

.loader-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; /* Asegura que esté sobre otros elementos */
}

.loader {
    width: 48px;
    height: 48px;
    border: 5px solid #9ba8e3;
    border-bottom-color: #386796;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}


.selfGuidedTableTd {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
    text-align: center;
}
.selfGuidedTableTr {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
    text-align: center;
}

.mainFilterDiv{
    height: auto;
    width: 250px;
    background-color: #fcfcfc;
    border-radius: 6px;
    padding: 10px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    position: absolute;
    z-index: 9;
    /* top: 20px;
    left: 20px; */
}

.filterSelect{
    width: 100%;
    margin-top: 5px;
    border: 1px solid lightgray;
    padding: 5px;
    margin-bottom: 20px;
}

.resetBtn{
    color:red;
    font-size:14px;
    cursor: pointer;
    padding: 4px 20px;
    border-radius: 6px;
}
.resetBtn:hover{
    color:red;
    font-size:14px;
    background-color: rgb(224, 224, 224);
    cursor: pointer;
    padding: 4px 20px;
    border-radius: 6px;
}
.filteredChip{
    padding: 5px 5px 6px 10px;
    border-radius: 20px;
    background-color: lightgray;
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    gap: 5px;
    min-width: 80px;
}
.chipCross{
    height: 18px;
    width: 18px;
    opacity: 0.8;
    cursor: pointer;
}
.chipCross:hover{
    height: 18px;
    width: 18px;
    opacity: 1;
    cursor: pointer;
}
.AddHighlightBtn{
    width: fit-content;
    cursor: pointer;
    font-weight: 600;
    font-size: 16px;
    background-color: #03c9d7;
    color: #fff;
    padding: 5px 15px;
    border-radius: 6px;
    margin-top: 20px;
}
.AddHighlightBtn:hover{
text-decoration: underline;
}
.highlightDiv{
    display: flex;
    gap: 20px;
    align-items: center;
    margin-bottom: 15px;
}
.voucherFileBtn{
    background-color: lightgray !important;
    border: 1px solid black;
    padding: 5px 10px;
    border-radius: 4px;
    font-size: 14px;
}

.voucherItem{
    min-width: 200px;
    max-width: 70%;
    overflow: scroll;
    white-space: nowrap;
}

.voucherItem > a:hover{
 color: rgb(15, 83, 231);
 text-decoration: underline;
}
.voucherItem > a{
    color: rgb(15, 83, 231);
}
.voucherItem::-webkit-scrollbar{
  display: none;
}