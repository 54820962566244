.mainTemplateBgImg{
    overflow-x: hidden;
    overflow-y: scroll;
    display: block;
    width: 100%;
    background-image: url('../../data/templateBgImg1.png'),url('../../data/templateBgImg2.png');
    background-position: right 25%, left 65%;
    width: 100%;
    height: 100%;
    background-size: 40%;
    background-repeat: no-repeat;
    font-family: 'Poppins';
    background-color: #F0F0F0;
}