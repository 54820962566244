.LoginMainContainer{
    background-image: url('../../data/loginMainBg.png');
    width: 100vw;
    height: 100vh;
    /* display: flex;
    justify-content: center;
    align-items: center; */
    background-position: 100% 100%;
    background-repeat: no-repeat;
    background-size: cover;
}
.LoginInnerContainer{
    width: 400px;
    margin: 0px auto;
    padding: 50px 20px;
}
.loginInputCss{
    padding: 7px 11px;
    font-size: 16px;
    line-height: 1.5;
    border-radius: 8px;
    color: rgba(0, 0, 0, 0.88);
    background-color: #ffffff;
    border-width: 1px;
    border-style: solid;
    border-color: #d9d9d9;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    margin-bottom: 30px;
}
.loginInputCss:hover{
    border-color: #4096ff;
}
.loginInputCss > input{
    width: 100%;
    outline: none;
}
.loginBtn{
    font-size: 16px;
    height: 40px;
    padding: 6.5px 15px;
    border-radius: 8px;
    color: #fff;
    background: #1677ff;
    box-shadow: 0 2px 0 rgba(5, 145, 255, 0.1);
    cursor: pointer;
    width: 100%;
    margin-top: 10px;
}
.loginBtn:hover{
    color: #fff;
    background: #4096ff;
}
.inputErrorMsg {
    font-size: 13px;
    color: #ff3180;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0px 10px;
    margin-top: -20px;
    margin-bottom: 10px;
  }
  .two-factor-form{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
  .two-factor-form>input{
    width: 42px;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid gray;
    padding: 5px;
    border-radius: 10px;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    color: #000;
  }
  .backArrowiconDiv{
    background-color: #fff;
    /* box-shadow: 0 2px 0 rgba(0, 0, 0, 0.1); */
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    padding: 8px 15px;
    font-size: 16px;
    color: #000;
    cursor: pointer;
    width: max-content;
    border-radius: 8px;
    position: absolute;
    top: 30px;
    left: 30px;
  }
  .backArrowiconDiv>img{
   width: 20px;
  }